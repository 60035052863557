<template>
  <div v-show="opened" class="remocao-local-map">
    <div class="rl-endereco-input">
      <form v-on:submit.prevent.stop="search" class="flex justify-between w-full">
        <u-input ref="input" required autofocus placeholder="Digite o endereço para pesquisar no mapa" class="col-grow"
                 v-model="endereco" />
        <u-btn type="submit" size="sm" class="m-l" color="primary" label="pesquisar" />
        <u-btn v-if="this.latLng" size="sm" class="m-l-xs" color="positive" label="confirmar endereço"
               @click="confirm" />
        <u-btn size="sm" class="m-l-xs" color="negative" label="cancelar" @click="hide" />
      </form>
    </div>
    <div class="google-map"
         style="z-index:14000;width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0"
         ref="googleMap"></div>
  </div>
</template>

<script>
import GoogleMap from '../../../../reuse/map/google-maps'
import {UInput} from 'uloc-vue'

export default {
  name: 'LocalRemocao',
  mixins: [GoogleMap],
  components: {UInput},
  data () {
    return {
      opened: false,
      endereco: null,
      latLng: null
    }
  },
  async mounted () {
    document.body.appendChild(this.$el)
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 200)
    })
  },
  destroyed () {
    document.body.removeChild(this.$el)
  },
  methods: {
    search () {
      this.searchAddressInMap(this.endereco)
    },
    changeAddress (address) {
      this.endereco = address
    },
    changeLatLng (location) {
      this.latLng = location.toJSON()
    },
    getLocation () {
      return {
        address: this.endereco,
        latLng: this.latLng
      }
    },
    confirm () {
      this.$emit('confirm', this.getLocation())
      if (typeof this.$callbackConfirm === 'function') {
        this.$callbackConfirm(this.getLocation())
      }
      this.hide()
    },
    toggle () {
      this.opened = !this.opened
    },
    simpleShow () {
      this.opened = true
    },
    simpleHide () {
      this.opened = false
    },
    show (location, callbackConfirm) {
      this.$callbackConfirm && delete this.$callbackConfirm
      if (typeof callbackConfirm === 'function') {
        this.$callbackConfirm = callbackConfirm
      }
      this.opened = true
      if (location.lat && location.lng) {
        setTimeout(() => {
          let latlng = new this.google.maps.LatLng(location.lat, location.lng)
          if (!this.$geocodeAddressMarker) {
            let mapComponent = this
            this.$geocodeAddressMarker && this.$geocodeAddressMarker.setMap(null)
            this.$geocodeAddressMarker && delete this.$geocodeAddressMarker
            this.$geocodeAddressMarker = new mapComponent.google.maps.Marker({
              map: mapComponent.map,
              position: location,
              title: this.geocodeAddressTitle
            });

            mapComponent.maxZoomService.getMaxZoomAtLatLng(location, (response) => {
              if (response.status !== 'OK') {
                mapComponent.map.setZoom(11)
              } else {
                mapComponent.map.setZoom(response.zoom)
              }
            });
          }
          console.log('Set actual position', latlng, location)
          this.$geocodeAddressMarker.setPosition(latlng)
          this.map.panTo(this.$geocodeAddressMarker.position)
        }, 1000)
        // this.geocodeLatLng({lat: location.lat, lng: location.lng})
      }
    },
    hide () {
      this.opened = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .remocao-local-map {
    position: fixed
    bottom: 0
    left 0
    width 100%
    height 45vh
    z-index 14000
    -webkit-box-shadow: -1px -6px 17px -1px rgba(0, 0, 0, 0.24)
    -moz-box-shadow: -1px -6px 17px -1px rgba(0, 0, 0, 0.24)
    box-shadow: -1px -6px 17px -1px rgba(0, 0, 0, 0.24)

    .rl-endereco-input {
      position: absolute
      bottom: 20px;
      width 55%;
      left 0
      right 0
      margin auto
      z-index 14001
      display flex
      background-color #FFFFFF
      padding 10px
      border-radius 5px
    }
  }
</style>
